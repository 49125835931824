// Imports for this language
import analytics from './analytics.json';
import bricks from './bricks.json';
import campaignOverview from './campaign-overview.json';
import campaigns from './campaigns.json';
import common from './common.json';
import concepts from './concepts.json';
import contentSpace from './content-space.json';
import creativesV2 from './creatives-v2.json';
import creativeManagement from './creative-management.json';
import editor from './editor.json';
import dashboard from './dashboard.json';
import feedManagement from './feed-management.json';
import interfaceBuilder from './interface-builder.json';
import routes from './routes.json';
import settings from './settings.json';
import templateDesigner from './template-designer.json';
import uiBase from './ui-base.json';
import uiComponents from './ui-components.json';
import sidekick from './sidekick.json';
import assetGalleryDialog from './asset-gallery-dialog.json';

// Build the translation object.
export default {
    analytics: analytics,
    bricks: bricks,
    'campaign-overview': campaignOverview,
    campaigns: campaigns,
    common: common,
    concepts: concepts,
    'creatives-v2': creativesV2,
    'creative-management': creativeManagement,
    dashboard: dashboard,
    editor: editor,
    'feed-management': feedManagement,
    'interface-builder': interfaceBuilder,
    routes: routes,
    settings: settings,
    'template-designer': templateDesigner,
    'content-space': contentSpace,
    'ui-base': uiBase,
    'ui-components': uiComponents,
    sidekick: sidekick,
    'asset-gallery-dialog': assetGalleryDialog
};
